@font-face {
  font-family: 'Taviraj';
  src: url('../assets/fonts/NewYorkExtraLargeSemibold.otf') format('otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Libre Bodoni';
  src: url('../assets/fonts/LibreBodoniBold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

:root {
  --min-screen-width: 320px;
  --body-background: #fdfdff;
  --container-width: 375px;
}

html {
  overflow-x: hidden;
}

* {
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  min-width: 320px;
  height: var(--full-height, 100vh);
  background-color: var(--body-background, #fdfdff);
  color: #2d3240;
  background-position: center top;
  background-size: contain;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0;
}

#root {
  position: relative;
  height: var(--full-height, 100vh);
  height: 100dvh;
}

/* Modern CSS Reset - https://dev.to/hankchizljaw/a-modern-css-reset-6p3 */

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul,
ol {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements */
ul,
ol {
  list-style: none;
}

a {
  text-decoration-skip-ink: auto;
  text-decoration: none;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select,
label {
  font: inherit;
  -webkit-tap-highlight-color: transparent;
}

#root .analyzingCarousel {
  margin: -30px -20px;
  padding: 30px 20px;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.fade-enter {
  opacity: 0;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 350ms ease-in;
}

.fade-exit {
  opacity: 1;
  z-index: -1;
  position: relative;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  z-index: -1;
  position: relative;
}

#chat-widget-container {
  display: var(--livechat-visibility, none);
}
